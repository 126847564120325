import React from 'react'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import { GatsbyImage } from 'gatsby-plugin-image'
import Video from 'src/components/Video'

const Wrapper = styled.div`
`

const MediaImage = styled(GatsbyImage)`
  ${ ({ setHeight }) => setHeight !== 'auto' ? `
    height: ${ setHeight };
    .gatsby-image-wrapper {
      > div {
        background: transparent !important;
      }
    }
    > div {
      background: transparent !important;
      height: ${ setHeight };
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

const MediaVideo = styled(Video)`
  ${ ({ setHeight }) => setHeight !== 'auto' ? `
    > div {
      height: ${ setHeight };
      min-height: 40vw;
      position: relative;
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

let settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 1000,
  fade: true,
  autoplay: true,
  pauseOnHover: false,
  autoplaySpeed: 3000,
  adaptiveHeight: false,
  accessibility: false,
}

const Container = ({ slides }) => {
	return (
		<Wrapper>
			<Slider {...settings}>
				{slides.map((slide, index) => {
					let type = 'image'
					if (slide.file.contentType.includes('video')) {
						type = 'video'
					}
					if (type === 'image') {
						return (
	            <MediaImage
	              image={slide.gatsbyImageData}
	              setHeight='100vh'
	              placeholder={false}
	            />
	          )
          } else if (type === 'video') {
          	return (
	            <MediaVideo
	              video={slide}
	              playing={true}
	              loop={true}
	              setHeight='100vh'
	              autoplay={true}
	            />
	          )
					}
				})}
			</Slider>
		</Wrapper>
	)
}

export default Container
