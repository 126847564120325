import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Video from 'src/components/Video'
import { GatsbyImage } from 'gatsby-plugin-image'
import TextLockup from 'src/components/TextLockup'
import { mq, gridSettings } from 'src/styles'

const Wrapper = styled(Section)`
  text-align: ${ ({ alignment }) => alignment };
`

const MediaScrollEntrance = styled(ScrollEntrance)`
  display: inline-block;
  vertical-align: top;
  width: 100%;
`

const ColumnWrapper = styled.div`
  .gatsby-image-wrapper,
  .video-wrapper {
    margin-bottom: 24px;
    width: 100%;
  }
`

const ColumnsGrid = styled(Grid)`
  width: 100%;
  ${ ({ alignment, vAlign, smallCols, mediumCols, largeCols, colGap }) => alignment === 'center' ? `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0 !important;
    align-items: ${ vAlign === 'bottom' ? 'flex-end' : vAlign };
    margin-left: calc(${ gridSettings.colSpacingSmall || colGap } / -2);
    margin-right: calc(${ gridSettings.colSpacingSmall || colGap } / -2);
    width: auto;
    ${ mq.mediumAndUp } {
      margin-left: calc(${ gridSettings.colSpacingMedium || colGap } / -2);
      margin-right: calc(${ gridSettings.colSpacingMedium || colGap } / -2);
    }
    ${ mq.largeAndUp } {
      margin-left: calc(${ gridSettings.colSpacingLarge || colGap } / -2);
      margin-right: calc(${ gridSettings.colSpacingLarge || colGap } / -2);
    }
    ${ ColumnWrapper } {
      width: ${ 100 / smallCols }%;
      padding-left: calc(${ gridSettings.colSpacingSmall || colGap }/2);
      padding-right: calc(${ gridSettings.colSpacingSmall || colGap }/2);
      ${ mq.mediumAndUp } {
        width: ${ 100 / mediumCols }%;
        padding-left: calc(${ gridSettings.colSpacingMedium || colGap }/2);
        padding-right: calc(${ gridSettings.colSpacingMedium || colGap }/2);
      }
      ${ mq.largeAndUp } {
        width: ${ 100 / largeCols }%;
        padding-left: calc(${ gridSettings.colSpacingLarge || colGap }/2);
        padding-right: calc(${ gridSettings.colSpacingLarge || colGap }/2);
      }
    }
  ` : `` }
`

const gridSetup = {
  1: '[1]',
  2: '[1] [1]',
  3: '[1] [1] [1]',
  4: '[1] [1] [1] [1]',
  5: '[1] [1] [1] [1] [1]',
  6: '[1] [1] [1] [1] [1] [1]'
}

const Columns = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  columns,
  alignment,
  verticalAlignment = 'top',
  id,
  desktopColumnCount = 3,
  tabletColumnCount = 2,
  mobileColumnCount = 1
}) => {
  return (
    <Wrapper
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      alignment={alignment === null ? 'left' : alignment}
    >
      <Grid small="1 [12] 1">
        <ColumnsGrid
          small={gridSetup[mobileColumnCount || 1]}
          medium={gridSetup[tabletColumnCount || 2]}
          large={gridSetup[desktopColumnCount || 3]}
          smallCols={mobileColumnCount}
          mediumCols={tabletColumnCount}
          largeCols={desktopColumnCount}
          vAlign={verticalAlignment}
          alignment={alignment}
          rowGap={['7vw', '7vw', '80px']}
          colGap="2vw"
        >
          {columns.map((column, index) => {
            let sizes = '600px'
            if (column?.media && column?.media?.gatsbyImageData) {
              sizes = '(min-width: ' + mq.mediumBreakpoint + 'px) ' + (86 / desktopColumnCount) + 'vw, (min-width: ' + mq.smallBreakpoint + 'px) ' + (86 / tabletColumnCount) + 'vw, ' + (86 / mobileColumnCount) + 'vw'
            }
            return (
              <ColumnWrapper
                alignment={alignment === null ? 'left' : alignment}
                index={index}
                colCount={columns.length}
                key={column.id + '-' + index + '-' + id}
              >
                {(column.media && column.media !== null) && (
                  <MediaScrollEntrance>
                    {column.media.file.contentType.includes('video') ? (
                      <Video video={column.media} />
                    ) : (
                      <GatsbyImage image={column.media.gatsbyImageData} sizes={sizes} />
                    )}
                  </MediaScrollEntrance>
                )}
                <TextLockup
                  entranceDelay={column.media ? 1 : 0}
                  eyebrow={column.eyebrow}
                  text={column.text}
                  textSize={column.paragraphSize}
                  actions={column.actions}
                />
              </ColumnWrapper>
            )
          })}
        </ColumnsGrid>
      </Grid>
    </Wrapper>
  );
}

export default Columns
