import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import { GatsbyImage } from 'gatsby-plugin-image'
import Video from 'src/components/Video'
import TextLockup from 'src/components/TextLockup'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { mq, util } from 'src/styles'

const FFSection = styled(Section)``

const ColumnWrapper = styled.div`
  ${ ({ padded, type }) => padded && type !== 'media' ? `
    ${ util.responsiveStyles('padding-top', 150, 102, 66, 52) }
    ${ util.responsiveStyles('padding-bottom', 150, 102, 66, 52) }
  ` : `
    ${ type === 'media' ? `
      height: 100%;
      > div {
        height: 100%;
      }
      .gatsby-image-wrapper {
        background: #ccc;
        height: 100%;
      }
    ` : `` }
  ` }
  ${ ({ textAlignment }) => textAlignment === 'center' ? `
    h1 {
      max-width: 12em;
    }
    h2 {
      max-width: 12em;
    }
    p {
      max-width: 28em;
    }
  ` : `
    h1 {
      max-width: 8em;
    }
    h2 {
      max-width: 8em;
    }
  ` }
`

const arrangeMedia = {
  left: {
    gridSetup: {
      normal: '2 [11] 2 [11] 2',
      large: '2 [12] 2 [10] 2',
      extraLarge: '2 [13] 2 [9] 2'
    }
  },
  right: {
    gridSetup: {
      normal: '2 [11] 2 [11] 2',
      large: '2 [12] 2 [10] 2',
      extraLarge: '2 [13] 2 [9] 2'
    }
  },
  bleedLeft: {
    gridSetup: {
      normal: '[13] 2 [11] 2',
      large: '[14] 2 [10] 2',
      extraLarge: '[15] 2 [9] 2'
    }
  },
  bleedRight: {
    gridSetup: {
      normal: '[13] 2 [11] 2',
      large: '[14] 2 [10] 2',
      extraLarge: '[15] 2 [9] 2'
    }
  }
}

const mediaSizes = {
  noBleed: {
    normal: 100 / 28 * 11,
    large: 100 / 28 * 12,
    extraLarge: 100 / 28 * 13
  },
  bleed: {
    normal: 100 / 28 * 13,
    large: 100 / 28 * 14,
    extraLarge: 100 / 28 * 15
  }
}

const FiftyFifty = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  media,
  mediaPlacement,
  mediaWidth,
  eyebrow,
  text,
  actions,
  paragraphSize,
  verticalAlignment,
  isFirstSection,
  textAlignment
}) => {
  if (!mediaPlacement || mediaPlacement === null) {
    mediaPlacement = 'left'
  }
  let sizes = '40vw'
  let padded = false
  if (media.gatsbyImageData) {
    let imageSize = mediaSizes.noBleed[mediaWidth]
    if (mediaPlacement.includes('bleed')) {
      padded = false
      imageSize = mediaSizes.bleed[mediaWidth]
    }
    sizes = '(min-width: ' + mq.mediumBreakpoint + 'px) ' + imageSize + 'vw, 86vw'
  }
  return (
    <FFSection
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      padded={padded}
    >
      <Grid
        small={padded ? '1 [12] 1' : '[1]'}
        large={arrangeMedia[mediaPlacement || 'left'].gridSetup[mediaWidth || 'normal']}
        rowGap={padded ? ['7vw', '7vw', '80px'] : 0}
        vAlign={verticalAlignment}
        gridDirection={mediaPlacement.includes('right') || mediaPlacement.includes('Right') ? 'rtl' : 'ltr'}
      >
        <ColumnWrapper padded={!padded} type='media'>
          {media.file.contentType === 'video/mp4' ? (
            <ScrollEntrance delay={0 /* TBD */}>
              <Video video={media} />
            </ScrollEntrance>
          ) : (
            <ScrollEntrance delay={0 /* TBD */}>
              <GatsbyImage image={media.gatsbyImageData} sizes={sizes} loading={isFirstSection ? 'eager' : 'lazy'} />
            </ScrollEntrance>
          )}
        </ColumnWrapper>

        <ColumnWrapper padded={!padded} textAlignment={textAlignment}>
          <Grid
            small={padded ? '[1]' : '1 [12] 1'}
            // large={padded ? '[1]' : '1 [4] 1'}
          >
            <div>
              <TextLockup
                entranceDelay={1}
                eyebrow={eyebrow}
                text={text}
                textSize={paragraphSize}
                actions={actions}
                alignment={textAlignment || 'left'}
              />
            </div>
          </Grid>
        </ColumnWrapper>
      </Grid>
    </FFSection>
  );
}

FiftyFifty.defaultProps = {
  mediaWidth: 'normal',
  mediaPlacement: 'left'
}

export default FiftyFifty
