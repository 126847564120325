import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'
import { typography, mq, colors, util, animations } from 'src/styles'
import Link from 'src/components/Link'
import BalanceText from 'react-balance-text'

const ThumbnailWrapper = styled(Link)`
	display: block;
	&:hover {
		img {
			transform: scale(1.05);
		}
	}
`

const ImageWrapper = styled.div`
	.gatsby-image-wrapper {
		background: ${ colors.lightGrey };
		width: 100%;
		img {
			width: 100%;
			transition: opacity ${ animations.mediumSpeed } ease-in-out, transform ${ animations.mediumSpeed } ease-in-out !important;
		}
		> div:first-of-type {
			${ mq.smallAndBelow } {
				// padding-top: 120% !important;
			}
		}
	}
`

const Title = styled.p`
	${ typography.h3 }
	font-family: ${ typography.secondaryFont };
	${ util.responsiveStyles('font-size', 26, 24, 22, 18) }
	margin: .7em 0 .4em 0;
`
const Description = styled.p`
	${ typography.bodySmall }
	color: ${ colors.textColor };
	font-family: ${ typography.secondaryFont };
	font-weight: normal;
	margin: 0;
	${ mq.mediumAndBelow } {
		display: none;
	}
`

const RestaurantThumbnail = ({ className, restaurant, showSummary = true }) => {
	if (!restaurant?.thumbnailSm?.gatsbyImageData) {
	return false
	}
	
	const sizes = '(min-width: ' + mq.largerBreakpoint + 'px) 22vw, (min-width: ' + mq.largeBreakpoint + 'px) 30vw, (min-width: ' + mq.mediumBreakpoint + 'px) 45vw, 600px'
	restaurant.thumbnailSm.gatsbyImageData.aspectRatio = 3 / 4
	restaurant.thumbnailSm.gatsbyImageData.width = 1200
	restaurant.thumbnailSm.gatsbyImageData.height = 1600
	return (
    <ThumbnailWrapper className={className} to={'/restaurants/' + restaurant.slug}>
			<ImageWrapper>
		  	<GatsbyImage image={restaurant.thumbnailSm.gatsbyImageData} sizes={sizes} />
	  	</ImageWrapper>
	  	<Title><BalanceText>{restaurant.title}</BalanceText></Title>
	  	{showSummary && (
		  	<Description><span>{restaurant.shortDescription}</span></Description>
	  	)}
	  </ThumbnailWrapper>
  )
}

export default RestaurantThumbnail
